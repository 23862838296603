import React from "react";
import { useTranslator } from "../../hooks/useTranslator";
import CalendarIconSVG from "../Ui/Icons/homePage/CalendarIcon";
import CerttificateIconSVG from "../Ui/Icons/homePage/CerttificateIcon";
import RoadIconSVG from "../Ui/Icons/homePage/RoadIcon";
import TruckIconSVG from "../Ui/Icons/homePage/TruckIcon";
import CallIconSVG from "../Ui/Icons/homePage/CallIcon";

export const Features = () => {
  const { __T } = useTranslator();

  // New array for feature items to remove duplicate code
  const features = [
    {
      Icon: CalendarIconSVG,
      title: __T("Monitoring and scheduling"),
      description: __T(
        "Monitoring and scheduling the dates of changing your vehicle oils at the nearest service center easily With a variety of the best car oils in the world and a wide range of branches covering all parts of the KSA"
      ),
      wrapperClasses:
        "flex flex-col items-start p-4 md:p-11 shadow-box rounded-xl text-start",
    },
    {
      Icon: CerttificateIconSVG,
      title: __T("Special varieties and best prices"),
      description: __T(
        "Get now All global motor oils with high quality services in the best service centers that provide to keep the manufacturers instructionswith discounted prices"
      ),
      wrapperClasses:
        "flex flex-col items-start p-4 md:p-11 shadow-box rounded-xl text-start",
    },
    {
      Icon: RoadIconSVG,
      title: __T("+1000 Service Center"),
      description: __T(
        "More than 1,000 service centers covering all parts of the KSA to provide oil change services and motor oil filters with The highest quality and professional technicians"
      ),
      wrapperClasses:
        "flex flex-col items-start p-4 md:p-11 shadow-box rounded-xl min-h-[362px] text-start",
    },
    {
      Icon: TruckIconSVG,
      title: __T("Mobile Service vans"),
      description: __T(
        "With the service Vans that comes to you at the place you specify It is an mobile integrated service center that provides car engine oil and engine filter change service"
      ),
      wrapperClasses:
        "flex flex-col items-start p-4 md:p-11 shadow-box rounded-xl text-start",
    },
    {
      Icon: CallIconSVG,
      title: __T("24/7 Support"),
      description: __T(
        "Customer service around the clock with a Periodic follow-up to Remind you to change the cars engine oil for each car and follow up on the services bills that have been made in centers and saved on the system"
      ),
      wrapperClasses:
        "flex flex-col items-start p-4 md:p-11 shadow-box rounded-xl text-start",
    },
  ];

  return (
    <>
      <section className="m-4  text-center my-5  md:pt-12" id="features">
        <h3 className=" text-4xl font-bold">{__T("Our Features")}</h3>
        <p className="my-3 text-base font-semibold  ltr:leading-snug text-[#555658]">
          {__T("Work smarter with powerful features")}
        </p>
        <div className="container mx-auto grid justify-center gap-4 md:gap-10 sm:grid-cols-2 lg:grid-cols-3">
          {/* Header block */}

          {/* Feature items rendered from the features array */}
          {features.map((feature, idx) => {
            const { Icon, title, description, wrapperClasses } = feature;
            return (
              <div
                key={idx}
                className="flex flex-col items-center shadow-md text-center rounded-2xl p-4 md:p-9"
              >
                <Icon className="w-24 h-24 text-white" />
                <h3 className="my-3 text-xl text-black-100 font-semibold">
                  {title}
                </h3>
                <div className="space-y-1 text-gray-15 text-base ">
                  <p>{description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};
