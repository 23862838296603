import React from "react";
import { useTranslator } from "../../hooks/useTranslator";
import CheckIcon from "../Ui/Icons/homePage/CheckIcon";
import RiyalIcon from "../Ui/Icons/homePage/RiyalIcon";
import { Button } from "../Ui/Button";
import { useRouter } from "next/router";

export const Pricing = () => {
  const { __T } = useTranslator();
  const router = useRouter();
  const { locale: activeLocale } = router;
  const handleOnClick = () => {
    window.location.href = "https://company.kibreet.io/en/register";
  };

  // New reusable pricing card component for company packages
  interface PricingCardProps {
    title: string;
    price: number;
    vehicleLabel: string;
    yearLabel: string;
    features: { text: string; highlight?: boolean; color?: string }[];
    btnStyle: string;
    extraClass?: string;
  }

  const PricingCard: React.FC<PricingCardProps> = ({
    title,
    price,
    vehicleLabel,
    yearLabel,
    features,
    btnStyle,
    extraClass = "",
  }) => {
    return (
      <div
        className={`flex flex-col items-start py-4 md:py-8 shadow-box rounded-xl ${extraClass}`}
      >
        <div className="rounded-xl px-4 md:px-11 pb-4">
          <h4 className="text-black-100 text-xl mb-3 text-start">{title}</h4>
          <p className="text-gray-15 text-base mb-3 flex items-center">
            <span className="text-brand-100 text-3xl font-extrabold flex">
              <RiyalIcon /> {price}
            </span>
            /{yearLabel}
          </p>
          <p className="text-gray-15 text-base text-start">{vehicleLabel}</p>
        </div>
        <div className="space-y-1 text-gray-15 text-md text-start border-t border-gray-5 w-full ">
          <ul className="my-1 leading-extra-loose text-md p-4 md:px-5 text-start">
            {features.map((feat, idx) => (
              <li key={idx}>
                <span>
                  <CheckIcon
                    width={14}
                    height={14}
                    className="inline-block ltr:mr-1 rtl:ml-1 text-success-700"
                    {...(feat.color ? { color: feat.color } : {})}
                  />
                </span>
                {feat.text}
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full px-4 md:px-5">
          <Button
            buttonStyle={btnStyle as any}
            onClick={handleOnClick}
            rounded="xl"
            size="2xl"
            type="button"
            className="w-full"
          >
            {__T("Choose Now")}
          </Button>
        </div>
      </div>
    );
  };

  // Define companies packages data
  const companyPackages = [
    {
      title: __T("Silver"),
      price: 60,
      vehicleLabel: __T("For one vehicle"),
      yearLabel: __T("year"),
      features: [
        { text: __T("Regular follow-up") },
        { text: __T("Services analytics") },
        { text: __T("Tax E-Invoices") },
        { text: __T("Digital wallet") },
        {
          text: __T("Number of vehicles {number}", { number: "6:10" }),
          highlight: true,
        },
        {
          text: __T("NFC chip price : {price} SAR", { price: 25 }),
          highlight: true,
        },
      ],
      btnStyle: "outlinePrimary",
    },
    {
      title: __T("Gold"),
      price: 55,
      vehicleLabel: __T("For one vehicle"),
      yearLabel: __T("year"),
      features: [
        { text: __T("Regular follow-up") },
        { text: __T("Services analytics") },
        { text: __T("Tax E-Invoices") },
        { text: __T("Digital wallet") },
        {
          text: __T("Number of vehicles {number}", { number: "11:30" }),
          highlight: true,
        },
        {
          text: __T("NFC chip price : {price} SAR", { price: 25 }),
          highlight: true,
        },
      ],
      btnStyle: "outlinePrimary",
    },
    {
      title: __T("Platinum"),
      price: 50,
      vehicleLabel: __T("For one vehicle"),
      yearLabel: __T("year"),
      features: [
        { text: __T("Regular follow-up") },
        { text: __T("Services analytics") },
        { text: __T("Tax E-Invoices") },
        { text: __T("Digital wallet") },
        {
          text: __T("Number of vehicles {number}", { number: "31:100" }),
          highlight: true,
        },
        {
          text: __T("NFC chip price : {price} SAR", { price: 25 }),
          highlight: true,
        },
      ],
      btnStyle: "outlinePrimary",
    },
    {
      title: __T("Titanium"),
      price: 50,
      vehicleLabel: __T("For one vehicle"),
      yearLabel: __T("year"),
      features: [
        { text: __T("Regular follow-up") },
        { text: __T("Services analytics") },
        { text: __T("Tax E-Invoices") },
        { text: __T("Digital wallet") },
        {
          text: __T("Number of vehicles {number}", { number: "101:1000" }),
          highlight: true,
        },
        {
          text: __T("NFC chip price : {price} SAR", { price: 25 }),
          highlight: true,
        },
      ],
      btnStyle: "outlinePrimary",
    },
  ];

  return (
    <>
      <section
        className="text-center mb-10 md:pt-12 container mx-auto"
        id="pricing"
      >
        <div className="rtl:flex-col-reverse rtl:flex my-11">
          <h3 className="text-black-100 md:text-4xl text-3xl font-extrabold uppercase">
            {__T("Best plans, pay what you use")}
          </h3>
        </div>
        <div className="grid md:justify-center gap-4 md:gap-5 sm:grid-cols-2 lg:grid-cols-4 p-4">
          {companyPackages.map((pkg, index) => (
            <PricingCard
              key={index}
              title={pkg.title}
              price={pkg.price}
              vehicleLabel={pkg.vehicleLabel}
              yearLabel={pkg.yearLabel}
              features={pkg.features}
              btnStyle={pkg.btnStyle}
            />
          ))}
        </div>
      </section>
      <section className="text-center mb-10 pt-5">
        <div className="container mx-auto  ">
          <div className="w-full ltr:pl-10 rtl:pr-10 bg-[url('../public/images/banner-bg.png')] bg-no-repeat bg-cover bg-center py-10 rounded-3xl">
            <div className="w-full rtl:flex rtl:flex-col-reverse p-4 md:p-0 mb-11 space-y-4">
              <h3 className="text-white md:text-4xl text-3xl font-extrabold text-center uppercase">
                {__T("Individuals Package")}
              </h3>
              <p className="text-base text-white md:w-2/3 mx-auto">
                {__T("+1000 Service Center")} {__T("&")}{" "}
                <span className="text-brand-100">{__T("Service vans")}</span>{" "}
                {__T(
                  "covering all parts of the KSA to provide oil change services and motor oil filters with The highest quality and professional technicians."
                )}
              </p>
            </div>
            <div className="w-full md:flex">
              <div className="max-w-sm w-full p-4 md:p-0 bg-white rounded-2xl">
                <div className="flex flex-col items-start p-4 md:p-11 shadow-box rounded-xl">
                  <div className="rounded-xl text-start">
                    <h4 className="text-brand-100 text-3xl font-bold ">
                      {__T("FREE")}
                    </h4>
                    <p className="text-gray-15"> {__T("For All vehicles")}</p>
                  </div>

                  <div className="space-y-1 text-gray-15 text-md text-start">
                    <ul className="my-5 leading-extra-loose text-start">
                      <li>
                        <span>
                          {" "}
                          <CheckIcon
                            width={14}
                            height={14}
                            className="inline-block ltr:mr-1 rtl:ml-1 text-success-700"
                          />
                        </span>
                        {__T("Regular follow-up")}
                      </li>
                      <li>
                        <span>
                          {" "}
                          <CheckIcon
                            width={14}
                            height={14}
                            className="inline-block ltr:mr-1 rtl:ml-1 text-success-700"
                          />
                        </span>
                        {__T("Services analytics")}
                      </li>
                      <li>
                        <span>
                          {" "}
                          <CheckIcon
                            width={14}
                            height={14}
                            className="inline-block ltr:mr-1 rtl:ml-1 text-success-700"
                          />
                        </span>
                        {__T("Tax E-Invoices")}
                      </li>
                      <li>
                        <span>
                          {" "}
                          <CheckIcon
                            width={14}
                            height={14}
                            className="inline-block ltr:mr-1 rtl:ml-1 text-success-700"
                          />
                        </span>
                        {__T("Digital wallet")}
                      </li>
                      <li>
                        <span>
                          {" "}
                          <CheckIcon
                            width={14}
                            height={14}
                            color="#fbbf42"
                            className="inline-block ltr:mr-1 rtl:ml-1 text-success-700"
                          />
                        </span>
                        {__T("Number of vehicles {number}", { number: "1:5" })}
                      </li>
                      <li>
                        <span>
                          {" "}
                          <CheckIcon
                            width={14}
                            height={14}
                            color="#fbbf42"
                            className="inline-block ltr:mr-1 rtl:ml-1 text-success-700"
                          />
                        </span>
                        {__T("NFC chip price : {price} SAR", { price: 25 })}
                      </li>
                    </ul>
                  </div>
                  <div className="w-full">
                    <Button
                      buttonStyle="outlineSecondary"
                      onClick={handleOnClick}
                      rounded="xl"
                      size="2xl"
                      type="button"
                      className="w-full"
                    >
                      {__T("Choose Now")}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="md:w-4/5 w-full ">
                <div className="w-full mx-auto">
                  <div className="bg-[url('../public/images/poster.png')] w-full h-[400px] bg-no-repeat bg-cover md:bg-center bg-top"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
