import React from "react";
import Image from "next/image";
import { useTranslator } from "../../hooks/useTranslator";
import DashboardImg from "../../public/images/dashboard.png";
import { Button } from "../Ui/Button";

export const Banner = () => {
  const { __T } = useTranslator();

  return (
    <div className="relative overflow-hidden">
      <div className="absolute inset-0 z-0 hidden md:block">
        <svg
          viewBox="0 0 1440 320"
          className="absolute bottom-0 right-0 w-full h-48"
          preserveAspectRatio="none"
        >
          <path
            fill="#fbfbff"
            fillOpacity="1"
            d="M0,288L1440,160L1440,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div className="container  md:w-1/2 w-full mx-auto text-center pt-12 px-3 relative">
        <h1 className="md:text-5xl text-2xl font-bold uppercase mx-auto tracking-normal md:leading-snug leading-normal md:rtl:leading-normal text-white">
          {__T(
            "The best technical system in (KSA) to control the exchange of motor oils"
          )}
        </h1>

        <Button rounded="full" className="px-4 !uppercase my-4">
          {__T("Start now")}
        </Button>
        <Image
          src={DashboardImg.src}
          alt="dashboard"
          width={550}
          height={300}
          className="md:h-[550px] h-[300px] w-full  bg-no-repeat bg-contain md:bg-center bg-top relative"
        />
      </div>
    </div>
  );
};
