import React, { forwardRef, Ref, SVGProps } from "react";

const CallIconSVG = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="119"
      height="122"
      viewBox="0 0 119 122"
      fill="none"
      {...props}
      ref={ref}
    >
      <g filter="url(#filter0_d_18_1187)">
        <rect
          x="12.5"
          width="94"
          height="94"
          rx="47"
          fill="url(#paint0_linear_18_1187)"
          shapeRendering="crispEdges"
        />
        <path
          d="M76.1244 43.0148H76.0106V40.2252C76.0106 31.2643 68.7464 24 59.7853 24C50.8243 24 43.5601 31.2643 43.5601 40.2252V43.0148H42.8769C40.372 43.0148 38.4364 45.2351 38.4364 47.7971V54.9134C38.3733 57.3964 40.3351 59.4605 42.8182 59.5236C42.8378 59.5242 42.8573 59.5245 42.8769 59.5247H47.0898C47.6865 59.4967 48.1475 58.9901 48.1194 58.3934C48.1185 58.3719 48.1168 58.3505 48.1145 58.3291V44.3811C48.1145 43.698 47.716 43.0147 47.0898 43.0147H45.8373V40.2251C45.8373 32.5219 52.0821 26.2771 59.7853 26.2771C67.4885 26.2771 73.7333 32.5219 73.7333 40.2251V43.0147H72.4808C71.8546 43.0147 71.4561 43.6979 71.4561 44.3811V58.3291C71.3926 58.9231 71.8226 59.4562 72.4165 59.5197C72.4379 59.522 72.4594 59.5236 72.4808 59.5246H73.7902L73.6763 59.6954C71.968 61.9657 69.2874 63.2955 66.4461 63.282C65.8241 60.232 62.8473 58.2637 59.7974 58.8858C57.1892 59.4177 55.3088 61.7019 55.2877 64.3637C55.3189 67.4856 57.8587 70 60.9808 69.9998C62.5263 69.9747 63.9995 69.3405 65.0798 68.2349C65.8087 67.4885 66.2863 66.5332 66.4461 65.5022C70.0042 65.5157 73.3597 63.8485 75.4981 61.0047L76.5798 59.4106C79.0278 59.2399 80.5649 57.7028 80.5649 55.4824V48.3661C80.565 45.9183 78.7433 43.0148 76.1244 43.0148ZM45.8373 57.2475H42.8769C41.651 57.2176 40.6814 56.1997 40.7113 54.9738C40.7118 54.9537 40.7126 54.9335 40.7136 54.9133V47.7969C40.7136 46.4876 41.6245 45.292 42.8769 45.292H45.8373V57.2475ZM63.4289 66.641C62.798 67.3251 61.9115 67.7169 60.9809 67.7228C59.1287 67.6933 57.6255 66.2153 57.5651 64.3638C57.5643 62.5087 59.0675 61.0043 60.9226 61.0035C62.7777 61.0028 64.2821 62.506 64.2829 64.3611C64.2829 64.3619 64.2829 64.3629 64.2829 64.3638C64.3303 65.2091 64.0204 66.0354 63.4289 66.641ZM78.2878 55.4826C78.2878 57.0197 76.8076 57.2475 76.1244 57.2475H73.7333V45.2921H76.1244C77.3769 45.2921 78.2878 47.0569 78.2878 48.3663V55.4826Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_18_1187"
          x="0.5"
          y="0"
          width="118"
          height="122"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="16" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.94902 0 0 0 0 0.447059 0 0 0 0 0.596078 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_18_1187"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_18_1187"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_18_1187"
          x1="59.5"
          y1="0"
          x2="59.5"
          y2="94"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F3789F" />
          <stop offset="1" stopColor="#EC3755" />
        </linearGradient>
      </defs>
    </svg>
  );
};
const ForwardRef = forwardRef(CallIconSVG);
export default ForwardRef;
