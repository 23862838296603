import { AboutUs } from "../../components/AboutUs/AboutUs";
import { Banner } from "../../components/Banner/Banner";
import { ChooseUs } from "../../components/ChooseUs/ChooseUs";
import { Features } from "../../components/Features/Features";
import { HomeContent } from "../../components/HomeContent/HomeContent";
import { AppFooter } from "../../components/Layout/Footer";
import { AppHeader } from "../../components/Layout/Header";
import { Overview } from "../../components/Overview/Overview";
import { Pricing } from "../../components/Pricing/Pricing";
import { SeoMetaTags } from "../../components/SeoMetaTags";
import { Succeed } from "../../components/Succeed/Succeed";
import { useTranslator } from "../../hooks/useTranslator";
import arabicBrands from "../../constants/dataAr.json";
import englishBrands from "../../constants/data.json";

const Home = () => {
  const { __T, __TK } = useTranslator();
  const oilBrandsValues: string[] = Object.values(englishBrands);
  const oilBrandsValuesAr: string[] = Object.values(arabicBrands);
  const concatenatedString: string = oilBrandsValues.join(", ");
  const concatenatedStringAr: string = oilBrandsValuesAr.join(", ");

  return (
    <>
      <SeoMetaTags keywords={__TK(concatenatedString, concatenatedStringAr)} />
      <AppHeader />
      {/* Banner */}
      <div className='overflow-hidden mx-auto pt-12 bg-[url("../public/images/banner-bg.png")] bg-no-repeat bg-cover bg-center'>
        <Banner />
      </div>
      {/* about us */}
      {/* <div className="mt-6">
        <AboutUs />
      </div> */}
      {/* Features */}
      <Features />
      {/* Home Content */}
      <HomeContent />
      {/* Choose Us */}
      <ChooseUs />
      {/* Pricing */}
      <Pricing />
      {/* Succeed */}
      <Succeed />
      {/* Overview */}
      <Overview />
      <AppFooter />
    </>
  );
};
export default Home;
