/* eslint-disable @next/next/no-img-element */
import React from "react";
import { useTranslator } from "../../hooks/useTranslator";
import CheckIcon from "../Ui/Icons/homePage/CheckIcon";
import ChooseUsImg from "../../public/images/choos-kibreet.png";
import { Button } from "../Ui/Button";

// New component for list items
const CheckListItem = ({
  label,
  translator,
}: {
  label: string;
  translator: (text: string) => string;
}) => (
  <li className="text-black-100">
    <span>
      <CheckIcon
        width={14}
        height={14}
        className="inline-block ltr:mr-1 rtl:ml-1 text-brand-100"
      />
    </span>
    {translator(label)}
  </li>
);

export const HomeContent = () => {
  const { __T } = useTranslator();
  // Renamed function
  const handleOnClick = () => {
    window.location.href = "https://company.kibreet.io/en/register";
  };
  return (
    <>
      <section className=" py-8 px-3">
        <div className="container justify-center m-auto md:mt-8 ">
          <div className="w-full md:flex md:gap-4">
            <div className="md:w-1/2 w-full">
              <h2 className="md:text-4xl uppercase text-3xl mx-auto tracking-normal ltr:leading-snug text-black-100 font-extrabold">
                {__T("Why should you choose Kibreet?")}
              </h2>
              <p className="text-gray-15 mt-2">
                {__T(
                  "A fast and secure technology that allows you to save more than 25% of your vehicle’s oil budget. whatever your budget is, we can cover oil services for you and your fleet"
                )}
              </p>
              <ul className="mt-5 leading-loose ">
                <CheckListItem
                  label="Monitoring and scheduling the dates of changing your vehicle oils easily"
                  translator={__T}
                />
                <CheckListItem
                  label="Follow-up and technical security system from any manipulation or fraud"
                  translator={__T}
                />
                <CheckListItem
                  label="Special varieties and best prices at discounted prices"
                  translator={__T}
                />
                <CheckListItem
                  label="The best service centers cover all over the KSA"
                  translator={__T}
                />
                <CheckListItem
                  label="E-bill are reserved for audit at any time"
                  translator={__T}
                />
                <CheckListItem label="24/7 support" translator={__T} />
              </ul>
            </div>
            <div className="md:w-1/2 w-full">
              <img
                src={ChooseUsImg.src}
                className="w-full h-[500px] mx-auto object-contain"
                alt="choose-us"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
