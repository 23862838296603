import React from "react";
import { useTranslator } from "../../hooks/useTranslator";
import GrowthIconSVG from "../Ui/Icons/homePage/GrowthIcon";
import OrdersIconSVG from "../Ui/Icons/homePage/OrdersIcon";
import PlatformIconSVG from "../Ui/Icons/homePage/PlatformIcon";
import CallIconSVG from "../Ui/Icons/homePage/CallIcon";

export const Succeed = () => {
  const { __T } = useTranslator();

  const succeedItems = [
    {
      key: "sales-growth",
      title: __T("Sales Growth"),
      description: __T(
        "Increase your service centers sales of Motor Engine Oils By addingthe products , services and price for them , your branches location"
      ),
      bgClass: "bg-bleed-100",
      Icon: GrowthIconSVG,
      iconColor: "text-bleed-100",
    },
    {
      key: "unlimited-orders",
      title: __T("Unlimited orders"),
      description: __T(
        "As soon as you finish registration in Kibreet system your service centers will be able to receive change oil orders in a scheduled manner with More than 10,000 user"
      ),
      bgClass: "bg-navy-100",
      Icon: OrdersIconSVG,
      iconColor: "text-navy-100",
    },
    {
      key: "easy-platform",
      title: __T("Easy technical platform"),
      description: __T(
        "A technical platform with Easy interface which you can add all items and prices, services price and you can make discounts on specific types and increase sales opportunities inside & outside your area by using the requests on our service van’s"
      ),
      bgClass: "bg-bleed-100",
      Icon: PlatformIconSVG,
      iconColor: "text-bleed-100",
    },
    {
      key: "support-247",
      title: __T("24/7 Support"),
      description: __T(
        "Customer service around the clock are ready to receive inquiries at any time, with a technical support team available to answer all system questions and train your team on how to use the system"
      ),
      bgClass: "bg-navy-100",
      Icon: CallIconSVG,
      iconColor: "text-navy-100",
    },
  ];

  return (
    <section className=" bg-[#fbfbff] text-center mb-10">
      <div className="container">
        <h2 className="md:text-4xl text-3xl font-extrabold mx-auto tracking-normal uppercase ltr:leading-snug text-black-100 mb-10">
          {__T("We Can Help You Succeed")}
        </h2>
        <div className=" grid justify-center gap-4 grid-cols-2 ">
          {succeedItems.map(({ key, title, description, Icon }) => (
            <div
              key={key}
              className="flex  p-5 bg-white rounded-2xl items-center"
            >
              <div className="flex items-center shrink-0">
                
                <Icon className="w-24 h-24"/>
              </div>

              <div className="space-y-1 text-gray-15 text-md text-start">
                <h3 className="my-3 text-xl text-black-100 font-semibold">
                  {title}
                </h3>
                <p>{description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
