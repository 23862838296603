import React, { forwardRef, Ref, SVGProps } from "react";

const CalendarIconSVG = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="118"
      height="122"
      viewBox="0 0 118 122"
      fill="none"
      {...props}
      ref={ref}
    >
      <g filter="url(#filter0_d_18_1141)">
        <rect
          x="12"
          width="94"
          height="94"
          rx="47"
          fill="url(#paint0_linear_18_1141)"
          shapeRendering="crispEdges"
        />
        <g clipPath="url(#clip0_18_1141)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M65.7304 59.1882L68.4405 61.8983C68.8145 62.2723 69.4216 62.2723 69.7956 61.8983L75.216 56.478C75.59 56.104 75.59 55.4969 75.216 55.1229C74.8419 54.7489 74.2349 54.7489 73.8609 55.1229L69.1181 59.8657L67.0855 57.8331C66.7114 57.4591 66.1044 57.4591 65.7304 57.8331C65.3564 58.2071 65.3564 58.8142 65.7304 59.1882Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.4583 42.4001C48.4583 41.8414 48.236 41.3047 47.8411 40.9089C47.4454 40.5141 46.9087 40.2917 46.35 40.2917H44.8166C44.2579 40.2917 43.7213 40.5141 43.3255 40.9089C42.9306 41.3047 42.7083 41.8414 42.7083 42.4001V43.9334C42.7083 44.4921 42.9306 45.0288 43.3255 45.4246C43.7213 45.8194 44.2579 46.0417 44.8166 46.0417H46.35C46.9087 46.0417 47.4454 45.8194 47.8411 45.4246C48.236 45.0288 48.4583 44.4921 48.4583 43.9334V42.4001ZM46.5416 42.4001V43.9334C46.5416 43.9842 46.5215 44.0331 46.4851 44.0685C46.4496 44.105 46.4008 44.1251 46.35 44.1251H44.8166C44.7659 44.1251 44.717 44.105 44.6815 44.0685C44.6451 44.0331 44.625 43.9842 44.625 43.9334V42.4001C44.625 42.3493 44.6451 42.3004 44.6815 42.265C44.717 42.2285 44.7659 42.2084 44.8166 42.2084H46.35C46.4008 42.2084 46.4496 42.2285 46.4851 42.265C46.5215 42.3004 46.5416 42.3493 46.5416 42.4001Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.4583 50.0667C48.4583 49.508 48.236 48.9713 47.8411 48.5755C47.4454 48.1807 46.9087 47.9584 46.35 47.9584H44.8166C44.2579 47.9584 43.7213 48.1807 43.3255 48.5755C42.9306 48.9713 42.7083 49.508 42.7083 50.0667V51.6C42.7083 52.1588 42.9306 52.6954 43.3255 53.0912C43.7213 53.486 44.2579 53.7084 44.8166 53.7084H46.35C46.9087 53.7084 47.4454 53.486 47.8411 53.0912C48.236 52.6954 48.4583 52.1588 48.4583 51.6V50.0667ZM46.5416 50.0667V51.6C46.5416 51.6508 46.5215 51.6997 46.4851 51.7352C46.4496 51.7716 46.4008 51.7917 46.35 51.7917H44.8166C44.7659 51.7917 44.717 51.7716 44.6815 51.7352C44.6451 51.6997 44.625 51.6508 44.625 51.6V50.0667C44.625 50.0159 44.6451 49.967 44.6815 49.9316C44.717 49.8952 44.7659 49.875 44.8166 49.875H46.35C46.4008 49.875 46.4496 49.8952 46.4851 49.9316C46.5215 49.967 46.5416 50.0159 46.5416 50.0667Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.4583 57.7333C48.4583 57.1746 48.236 56.638 47.8411 56.2422C47.4454 55.8473 46.9087 55.625 46.35 55.625H44.8166C44.2579 55.625 43.7213 55.8473 43.3255 56.2422C42.9306 56.638 42.7083 57.1746 42.7083 57.7333V59.2667C42.7083 59.8254 42.9306 60.362 43.3255 60.7578C43.7213 61.1527 44.2579 61.375 44.8166 61.375H46.35C46.9087 61.375 47.4454 61.1527 47.8411 60.7578C48.236 60.362 48.4583 59.8254 48.4583 59.2667V57.7333ZM46.5416 57.7333V59.2667C46.5416 59.3175 46.5215 59.3663 46.4851 59.4018C46.4496 59.4382 46.4008 59.4583 46.35 59.4583H44.8166C44.7659 59.4583 44.717 59.4382 44.6815 59.4018C44.6451 59.3663 44.625 59.3175 44.625 59.2667V57.7333C44.625 57.6825 44.6451 57.6337 44.6815 57.5982C44.717 57.5618 44.7659 57.5417 44.8166 57.5417H46.35C46.4008 57.5417 46.4496 57.5618 46.4851 57.5982C46.5215 57.6337 46.5416 57.6825 46.5416 57.7333Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.125 42.4001C56.125 41.8414 55.9027 41.3047 55.5078 40.9089C55.112 40.5141 54.5754 40.2917 54.0167 40.2917H52.4833C51.9246 40.2917 51.388 40.5141 50.9922 40.9089C50.5973 41.3047 50.375 41.8414 50.375 42.4001V43.9334C50.375 44.4921 50.5973 45.0288 50.9922 45.4246C51.388 45.8194 51.9246 46.0417 52.4833 46.0417H54.0167C54.5754 46.0417 55.112 45.8194 55.5078 45.4246C55.9027 45.0288 56.125 44.4921 56.125 43.9334V42.4001ZM54.2083 42.4001V43.9334C54.2083 43.9842 54.1882 44.0331 54.1518 44.0685C54.1163 44.105 54.0675 44.1251 54.0167 44.1251H52.4833C52.4325 44.1251 52.3837 44.105 52.3482 44.0685C52.3118 44.0331 52.2917 43.9842 52.2917 43.9334V42.4001C52.2917 42.3493 52.3118 42.3004 52.3482 42.265C52.3837 42.2285 52.4325 42.2084 52.4833 42.2084H54.0167C54.0675 42.2084 54.1163 42.2285 54.1518 42.265C54.1882 42.3004 54.2083 42.3493 54.2083 42.4001Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.125 50.0667C56.125 49.508 55.9027 48.9713 55.5078 48.5755C55.112 48.1807 54.5754 47.9584 54.0167 47.9584H52.4833C51.9246 47.9584 51.388 48.1807 50.9922 48.5755C50.5973 48.9713 50.375 49.508 50.375 50.0667V51.6C50.375 52.1588 50.5973 52.6954 50.9922 53.0912C51.388 53.486 51.9246 53.7084 52.4833 53.7084H54.0167C54.5754 53.7084 55.112 53.486 55.5078 53.0912C55.9027 52.6954 56.125 52.1588 56.125 51.6V50.0667ZM54.2083 50.0667V51.6C54.2083 51.6508 54.1882 51.6997 54.1518 51.7352C54.1163 51.7716 54.0675 51.7917 54.0167 51.7917H52.4833C52.4325 51.7917 52.3837 51.7716 52.3482 51.7352C52.3118 51.6997 52.2917 51.6508 52.2917 51.6V50.0667C52.2917 50.0159 52.3118 49.967 52.3482 49.9316C52.3837 49.8952 52.4325 49.875 52.4833 49.875H54.0167C54.0675 49.875 54.1163 49.8952 54.1518 49.9316C54.1882 49.967 54.2083 50.0159 54.2083 50.0667Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.125 57.7333C56.125 57.1746 55.9027 56.638 55.5078 56.2422C55.112 55.8473 54.5754 55.625 54.0167 55.625H52.4833C51.9246 55.625 51.388 55.8473 50.9922 56.2422C50.5973 56.638 50.375 57.1746 50.375 57.7333V59.2667C50.375 59.8254 50.5973 60.362 50.9922 60.7578C51.388 61.1527 51.9246 61.375 52.4833 61.375H54.0167C54.5754 61.375 55.112 61.1527 55.5078 60.7578C55.9027 60.362 56.125 59.8254 56.125 59.2667V57.7333ZM54.2083 57.7333V59.2667C54.2083 59.3175 54.1882 59.3663 54.1518 59.4018C54.1163 59.4382 54.0675 59.4583 54.0167 59.4583H52.4833C52.4325 59.4583 52.3837 59.4382 52.3482 59.4018C52.3118 59.3663 52.2917 59.3175 52.2917 59.2667V57.7333C52.2917 57.6825 52.3118 57.6337 52.3482 57.5982C52.3837 57.5618 52.4325 57.5417 52.4833 57.5417H54.0167C54.0675 57.5417 54.1163 57.5618 54.1518 57.5982C54.1882 57.6337 54.2083 57.6825 54.2083 57.7333Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M63.7917 42.4001C63.7917 41.8414 63.5694 41.3047 63.1745 40.9089C62.7787 40.5141 62.2421 40.2917 61.6834 40.2917H60.15C59.5913 40.2917 59.0546 40.5141 58.6589 40.9089C58.264 41.3047 58.0417 41.8414 58.0417 42.4001V43.9334C58.0417 44.4921 58.264 45.0288 58.6589 45.4246C59.0546 45.8194 59.5913 46.0417 60.15 46.0417H61.6834C62.2421 46.0417 62.7787 45.8194 63.1745 45.4246C63.5694 45.0288 63.7917 44.4921 63.7917 43.9334V42.4001ZM61.875 42.4001V43.9334C61.875 43.9842 61.8549 44.0331 61.8185 44.0685C61.783 44.105 61.7341 44.1251 61.6834 44.1251H60.15C60.0992 44.1251 60.0504 44.105 60.0149 44.0685C59.9785 44.0331 59.9584 43.9842 59.9584 43.9334V42.4001C59.9584 42.3493 59.9785 42.3004 60.0149 42.265C60.0504 42.2285 60.0992 42.2084 60.15 42.2084H61.6834C61.7341 42.2084 61.783 42.2285 61.8185 42.265C61.8549 42.3004 61.875 42.3493 61.875 42.4001Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M71.4583 42.4001C71.4583 41.8414 71.236 41.3047 70.8411 40.9089C70.4454 40.5141 69.9087 40.2917 69.35 40.2917H67.8166C67.2579 40.2917 66.7213 40.5141 66.3255 40.9089C65.9306 41.3047 65.7083 41.8414 65.7083 42.4001V43.9334C65.7083 44.4921 65.9306 45.0288 66.3255 45.4246C66.7213 45.8194 67.2579 46.0417 67.8166 46.0417H69.35C69.9087 46.0417 70.4454 45.8194 70.8411 45.4246C71.236 45.0288 71.4583 44.4921 71.4583 43.9334V42.4001ZM69.5416 42.4001V43.9334C69.5416 43.9842 69.5215 44.0331 69.4851 44.0685C69.4496 44.105 69.4008 44.1251 69.35 44.1251H67.8166C67.7659 44.1251 67.717 44.105 67.6815 44.0685C67.6451 44.0331 67.625 43.9842 67.625 43.9334V42.4001C67.625 42.3493 67.6451 42.3004 67.6815 42.265C67.717 42.2285 67.7659 42.2084 67.8166 42.2084H69.35C69.4008 42.2084 69.4496 42.2285 69.4851 42.265C69.5215 42.3004 69.5416 42.3493 69.5416 42.4001Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.3704 65.2084H41.75C40.4792 65.2084 39.2602 64.7033 38.3613 63.8054C37.4634 62.9065 36.9583 61.6875 36.9583 60.4167V33.5834C36.9583 30.9374 39.104 28.7917 41.75 28.7917H44.625V27.8334C44.625 26.2454 45.912 24.9584 47.5 24.9584C49.0879 24.9584 50.375 26.2454 50.375 27.8334V28.7917H63.7916V27.8334C63.7916 26.2454 65.0787 24.9584 66.6666 24.9584C68.2546 24.9584 69.5416 26.2454 69.5416 27.8334V28.7917H72.4166C75.0626 28.7917 77.2083 30.9374 77.2083 33.5834V50.3705C79.5495 52.3054 81.0416 55.2292 81.0416 58.5C81.0416 64.3181 76.318 69.0417 70.5 69.0417C67.2292 69.0417 64.3053 67.5496 62.3704 65.2084ZM70.5 49.875C75.26 49.875 79.125 53.74 79.125 58.5C79.125 63.2601 75.26 67.125 70.5 67.125C65.7399 67.125 61.875 63.2601 61.875 58.5C61.875 53.74 65.7399 49.875 70.5 49.875ZM75.2916 49.1093V38.375H38.875V60.4167C38.875 61.1795 39.1778 61.9108 39.7174 62.4493C40.2559 62.9889 40.9871 63.2917 41.75 63.2917H61.1093C60.3733 61.8542 59.9583 60.225 59.9583 58.5C59.9583 52.682 64.6819 47.9584 70.5 47.9584C72.225 47.9584 73.8541 48.3733 75.2916 49.1093ZM69.5416 30.7084V31.6667C69.5416 33.2547 68.2546 34.5417 66.6666 34.5417C65.0787 34.5417 63.7916 33.2547 63.7916 31.6667V30.7084H50.375V31.6667C50.375 33.2547 49.0879 34.5417 47.5 34.5417C45.912 34.5417 44.625 33.2547 44.625 31.6667V30.7084H41.75C40.162 30.7084 38.875 31.9954 38.875 33.5834V36.4584H75.2916V33.5834C75.2916 31.9954 74.0046 30.7084 72.4166 30.7084H69.5416ZM67.625 27.8334V31.6667C67.625 32.1957 67.1956 32.625 66.6666 32.625C66.1376 32.625 65.7083 32.1957 65.7083 31.6667V27.8334C65.7083 27.3044 66.1376 26.875 66.6666 26.875C67.1956 26.875 67.625 27.3044 67.625 27.8334ZM48.4583 27.8334V31.6667C48.4583 32.1957 48.029 32.625 47.5 32.625C46.971 32.625 46.5416 32.1957 46.5416 31.6667V27.8334C46.5416 27.3044 46.971 26.875 47.5 26.875C48.029 26.875 48.4583 27.3044 48.4583 27.8334Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_18_1141"
          x="0"
          y="0"
          width="118"
          height="122"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="16" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.286275 0 0 0 0 0.764706 0 0 0 0 0.972549 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_18_1141"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_18_1141"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_18_1141"
          x1="59"
          y1="0"
          x2="59"
          y2="94"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#49C3F8" />
          <stop offset="1" stopColor="#2D7DED" />
        </linearGradient>
        <clipPath id="clip0_18_1141">
          <rect
            width="46"
            height="46"
            fill="white"
            transform="translate(36 24)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const ForwardRef = forwardRef(CalendarIconSVG);
export default ForwardRef;
